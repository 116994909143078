@import './theme';

html {
  background-color: var(--color-grey-00);
  color: var(--main-font-color);
  font-size: 62.5%;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;

  /* adds scroll locking and padding to top of page when clicking an anchor link */
  scroll-padding-top: var(--scroll-padding-top);

  @media (--xl) {
    /* We'll reach 72.5% at 2560 */
    font-size: min(72.5%, calc(62.5% + calc(100vw - 1920px) / 400));
  }
}

/* stylelint-disable-next-line selector-id-pattern */
#__next {
  width: 100%;
  min-height: 100vh;
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  min-height: stretch;
  font-family: var(--font-main);
  font-size: var(--font-size-base);
}

/* stylelint-disable-next-line selector-class-pattern */
.olderSafari {
  /* stylelint-disable-next-line selector-class-pattern */
  .scrollable-container {
    contain: unset;
  }
}

/* Elements used by the WindowSize singleton to watch the window height and width respectively */

/* They're defined here to make sure they're defined in the global space as we target them via IDs */
#vertical-sizer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
}

#horizontal-sizer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}

/* Ensures the nav is on top of the drift bot */
#drift-frame-controller {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: var(--z-index-10) !important;
}

/* stylelint-disable selector-class-pattern */
.intercom-lightweight-app,
.intercom-app,
#intercom-css-container,
#intercom-container,
#intercom-frame {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

#modal {
  position: fixed;
  z-index: var(--z-index-modal);
  top: 0;
  left: 0;
}

select {
  border: 0;
  appearance: none;
}

button {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--color-grey-100);
}

i,
em {
  font-style: normal;
}

a {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-decoration: inherit;

  /* color: var(--color-grey-100); */
}

/* stylelint-disable property-no-vendor-prefix */
input[type='number'] {
  &,
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;

    &:hover,
    &:focus {
      -moz-appearance: number-input;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition-delay: 99999s;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* stylelint-disable-line */
}

*:focus {
  outline: none;
}

a,
button {
  /* Hide focus styles if they're not needed, for example,
  when an element receives focus via the mouse. */
  &:focus:not(:focus-visible) {
    outline: 0;
  }

  /* Show focus styles on keyboard focus. */
  &:focus-visible {
    outline: 1px dotted var(--color-grey-100);
  }
}

.noScroll {
  position: fixed;
  width: 100%;

  /* fixes issue with ios open mobile nav and not being able to render the backdrop blur */
  /* stylelint-disable-next-line selector-id-pattern */
  #__next {
    min-height: calc(var(--vh, 1vh) * 100);
  }
}

.noSmoothScroll {
  scroll-behavior: auto;
}

.visuallyHidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
  opacity: 0.0001;
}
