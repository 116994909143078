:root {
  --overlay-heavy: linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), linear-gradient(180deg, rgb(0 0 0 / 0%) 50%, #000000 100%), linear-gradient(7.96deg, #000000 14.71%, rgb(0 0 0 / 0%) 78.17%);
  --overlay-medium: linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), linear-gradient(18.61deg, rgb(0 0 0 / 67.2%) 21.85%, rgb(0 0 0 / 0%) 73.55%);
  --overlay-light: linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), linear-gradient(18.61deg, rgb(0 0 0 / 50.4%) 21.85%, rgb(0 0 0 / 0%) 73.55%);
  --overlay-dim80: rgb(0 0 0 / 80%);
  --overlay-dim60: rgb(0 0 0 / 60%);
  --overlay-dim40: rgb(0 0 0 / 40%);
  --overlay-dim: rgb(0 0 0 / 20%);
  --overlay-none: none;
  --color-alpha-grey-80: rgba(46, 47, 64, 0.8);
  --color-alpha-grey-30: rgba(54, 56, 74, 0.2);
  --color-alpha-perano-10: rgba(189, 189, 244, 0.1);
  --color-alpha-cinder-50: rgba(13, 13, 24, 0.5);
  --color-alpha-hawkes-60: rgba(209, 209, 251, 0.6);
  --color-alpha-hawkes-20: rgba(209, 209, 251, 0.2);
  --color-alpha-black-5: rgba(0, 0, 0, 0.05);
  --color-alpha-black-10: rgba(0, 0, 0, 0.1);
  --color-alpha-black-20: rgba(0, 0, 0, 0.2);
  --color-alpha-black-25: rgba(0, 0, 0, 0.25);
  --color-alpha-black-30: rgba(0, 0, 0, 0.3);
  --color-alpha-black-40: rgba(0, 0, 0, 0.4);
  --color-alpha-black-50: rgba(0, 0, 0, 0.5);
  --color-alpha-black-60: rgba(0, 0, 0, 0.6);
  --color-alpha-black-70: rgba(0, 0, 0, 0.7);
  --color-alpha-black-80: rgba(0, 0, 0, 0.8);
  --color-alpha-black-90: rgba(0, 0, 0, 0.9);
  --color-alpha-black-100: rgba(0, 0, 0, 1);
  --color-alpha-dark-5: rgba(13, 13, 24, 0.05);
  --color-alpha-dark-10: rgba(13, 13, 24, 0.1);
  --color-alpha-dark-20: rgba(13, 13, 24, 0.2);
  --color-alpha-dark-30: rgba(13, 13, 24, 0.3);
  --color-alpha-dark-40: rgba(13, 13, 24, 0.4);
  --color-alpha-dark-50: rgba(13, 13, 24, 0.5);
  --color-alpha-dark-60: rgba(13, 13, 24, 0.6);
  --color-alpha-dark-70: rgba(13, 13, 24, 0.7);
  --color-alpha-dark-80: rgba(13, 13, 24, 0.8);
  --color-alpha-dark-90: rgba(13, 13, 24, 0.9);
  --color-alpha-light-5: rgba(157, 157, 255, 0.05);
  --color-alpha-light-10: rgba(189, 189, 244, 0.1);
  --color-alpha-light-15: rgba(209, 209, 251, 0.15);
  --color-alpha-light-20: rgba(209, 209, 251, 0.2);
  --color-alpha-light-30: rgba(220, 220, 255, 0.3);
  --color-alpha-light-40: rgba(228, 228, 255, 0.4);
  --color-alpha-light-50: rgba(234, 234, 255, 0.5);
  --color-alpha-light-60: rgba(234, 234, 255, 0.6);
  --color-alpha-light-70: rgba(233, 233, 255, 0.7);
  --color-alpha-light-80: rgba(240, 240, 255, 0.8);
  --color-alpha-light-90: rgba(241, 244, 255, 0.9);
  --color-grey-100: rgba(252, 252, 252, 1);
  --color-grey-97: rgba(245, 245, 248, 1);
  --color-grey-95: rgba(240, 240, 247, 1);
  --color-grey-93: rgba(232, 233, 243, 1);
  --color-grey-90: rgba(222, 223, 238, 1);
  --color-grey-88: rgba(213, 214, 234, 1);
  --color-grey-80: rgba(193, 194, 217, 1);
  --color-grey-70: rgba(163, 164, 191, 1);
  --color-grey-60: rgba(129, 133, 159, 1);
  --color-grey-50: rgba(106, 107, 131, 1);
  --color-grey-40: rgba(79, 81, 103, 1);
  --color-grey-30: rgba(54, 56, 74, 1);
  --color-grey-20: rgba(46, 47, 64, 1);
  --color-grey-15: rgba(37, 39, 54, 1);
  --color-grey-12: rgba(29, 31, 44, 1);
  --color-grey-10: rgba(22, 23, 34, 1);
  --color-grey-08: rgba(18, 19, 29, 1);
  --color-grey-05: rgba(13, 13, 24, 1);
  --color-grey-00: rgba(0, 0, 0, 1);
  --color-coral-dark: rgba(227, 107, 82, 1);
  --color-coral-light: rgba(252, 164, 147, 1);
  --color-coral: rgba(252, 133, 109, 1);
  --color-iris-dark: rgba(99, 44, 218, 1);
  --color-iris-light: rgba(159, 117, 249, 1);
  --color-iris: rgba(137, 82, 253, 1);
  --color-electric-dark: rgba(0, 184, 185, 1);
  --color-electric-light: rgba(135, 255, 255, 1);
  --color-electric: rgba(71, 235, 235, 1);
  --color-cobalt-dark: rgba(82, 55, 249, 1);
  --color-cobalt-light: rgba(99, 105, 255, 1);
  --color-cobalt-20: rgba(91, 83, 255, 0.2);
  --color-cobalt: rgba(91, 83, 255, 1);
  --color-turquoise-triumph: rgba(97, 153, 246, 1);
  --spacer-4: 0.4rem;
  --spacer-6: 0.6rem;
  --spacer-8: 0.8rem;
  --spacer-10: 1rem;
  --spacer-12: 1.2rem;
  --spacer-16: 1.6rem;
  --spacer-18: 1.8rem;
  --spacer-20: 2rem;
  --spacer-24: 2.4rem;
  --spacer-28: 2.8rem;
  --spacer-32: 3.2rem;
  --spacer-40: 4rem;
  --spacer-48: 4.8rem;
  --spacer-54: 5.4rem;
  --spacer-64: 6.4rem;
  --spacer-72: 7.2rem;
  --spacer-84: 8.4rem;
  --spacer-96: 9.6rem;
  --spacer-124: 12.4rem;
  --spacer-160: 16rem;
  --spacer-192: 19.2rem;
  --spacer-240: 24rem;
  --spacer-320: 32rem;
  --spacer-480: 48rem;
  --spacer-0: 0rem;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  padding: 0;
  border: 0;
  margin: 0;
  /* stylelint-disable-next-line declaration-block-no-shorthand-property-overrides */
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

a,
button {
  cursor: pointer;
  text-align: inherit;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --transparent: transparent;
  --color-accent-default: var(--color-grey-97);
  --color-accent: var(--color-accent-default);
}

/* stylelint-disable value-list-comma-newline-after */

/* stylelint-disable max-line-length */

/* stylelint-disable custom-property-empty-line-before */

:root {
  /* Font Settings */
  --font-size-base: 1.6rem;
  --main-font-color: var(--color-grey-100);

  /* Z-Indexes */
  --z-index-background-color: -10;
  --z-index-neg: -1;
  --z-index-0: 0;
  --z-index-1: 1;
  --z-index-2: 2;
  --z-index-3: 3;
  --z-index-4: 4;
  --z-index-5: 5;
  --z-index-6: 6;
  --z-index-7: 7;
  --z-index-8: 8;
  --z-index-9: 9;
  --z-index-10: 10;
  --z-index-nav: 100;
  --z-index-max: 105;
  --z-index-modal: 110;

  /* Shadows */

  --bento-glass-integrations-1: linear-gradient(221deg, #42545d, #203643 29%, #1c2935 45%, #1b242f 64%, #191d27 83%, #171820);
  --bento-glass-integrations-2: linear-gradient(226deg, #2e3d48 6%, #203643 18%, #1b242f 51%, #191d27 82%, #171820);
  --bento-glass-integrations-3: linear-gradient(208deg, #1e2e3a, #1c2833 19%, #1b242f 40%, #191d26 69%, #171820 88%, #171820);
  --bento-glass-integrations-4: linear-gradient(194deg, #1e2e3a, #1c2833 25%, #1b242f 38%, #191d26 69%, #171820 88%, #171820);
  --bento-hover-gradient: linear-gradient(47.17deg, #181821 7.98%, #1c2631 150.39%);

  --bento-glass-sub-integrations-1: linear-gradient(165.45deg, #272834 8.67%, #16171f 62.25%), linear-gradient(0deg, rgb(54 56 70 / 20%), rgb(54 56 70 / 20%));
  --bento-glass-sub-integrations-2: linear-gradient(165.45deg, #272834 8.67%, #16171f 62.25%), linear-gradient(0deg, rgb(54 56 70 / 20%), rgb(54 56 70 / 20%));
  --bento-glass-sub-integrations-3: linear-gradient(165.45deg, #272834 8.67%, #16171f 62.25%), linear-gradient(0deg, rgb(54 56 70 / 20%), rgb(54 56 70 / 20%));
  --bento-glass-sub-integrations-4: linear-gradient(165.45deg, #272834 8.67%, #16171f 62.25%), linear-gradient(0deg, rgb(54 56 70 / 20%), rgb(54 56 70 / 20%));

  /* borders */
  --bento-border: 1px solid rgb(54 56 70 / 20%);
  --glass-wrapper-border: 0.4rem;

  /* Computed grid variables */

  /* Grid edge margins */

  /* Grid column width */

  /* Max grid width, with margins */

  /* Max grid width, not including margins */

  /* Animations */ /* ease-out */ /* ease-out-soft */ /* ease-out-softer */ /* ease-in-out */ /* ease-mini-bounce */

  /* Aspect Ratios */

  /* per design system, everything (grid, type styles, margins, etc) fluidly scales up from 1.0 to 1.145 */
}

@media (min-width: 768px) {

:root {
    --glass-wrapper-border: 0.6rem;
}
  }

:root {
  --card-box-shadow: 84.6801px 149.711px 80px rgb(0 0 0 / 21%), 54.8852px 97.0347px 46.8519px rgb(0 0 0 / 15.9%), 32.6175px 57.6663px 25.4815px rgb(0 0 0 / 12.8%), 16.936px 29.9421px 13px rgb(0 0 0 / 10.5%), 6.89986px 12.1986px 6.51852px rgb(0 0 0 / 8.24%),
    1.56815px 2.77242px 3.14815px rgb(0 0 0 / 5.06%);
  --card-small-box-shadow: 32.6175px 57.6663px 25.4815px rgb(0 0 0 / 21.8%), 16.936px 29.9421px 13px rgb(0 0 0 / 15.5%), 6.89986px 12.1986px 6.51852px rgb(0 0 0 / 12.24%), 1.56815px 2.77242px 3.14815px rgb(0 0 0 / 5.06%);

  --button-box-shadow: 5px 7px 18px rgb(0 0 0 / 10%), 0 8px 21px rgb(1 0 19 / 15%);
  --button-glass-bg: radial-gradient(1386.01% 199.24% at 2.29% 8.82%, rgb(163 164 191 / 20%) 0%, rgb(193 194 217 / 11%) 44.34%);

  --button-border-color: rgba(#9d9dff, 0.05);

  --bento-box-shadow: 25px 38px 38px 0 rgb(0 0 0 / 45%);
  --faq-box-shadow: 57.4265px 55.6974px 109px rgb(0 0 0 / 38%), 29.0722px 28.1968px 47.5172px rgb(0 0 0 / 25.7%), 11.4853px 11.1395px 17.7125px rgb(0 0 0 / 19%);
  --card-background: linear-gradient(var(--bento-gradient-angle, 140deg), #1b1b26 var(--bento-gradient-start, -41.97%), #09090d var(--bento-gradient-end, 77.06%));
  --interactive-quotes-gradient: linear-gradient(70.41deg, #5d3730 -52%, #8b5f4d 26.88%, #343357 75%);

  --fullwidthimage-text-gradient: linear-gradient(180deg, rgb(0 0 0 / 0%) 0.46%, rgb(0 0 0 / 60%) 18%, #000000 48%);

  --nav-gradient-when-blurred: transparent;

  --nav-gradient-when-unblurred: linear-gradient(to bottom, rgb(0 0 0 / 90%) 50%, rgb(0 0 0 / 50%) 100%);

  --image-sequence-gradient: linear-gradient(30.53deg, rgb(0 0 0 / 90%) 41.93%, rgb(0 0 0 / 23.4%) 63.18%), linear-gradient(360deg, rgb(0 0 0 / 92%) 15.86%, rgb(0 0 0 / 0%) 56.66%);

  --stat-gradient-enterprise: linear-gradient(155.1deg, #fcfcfc -2.85%, rgb(252 252 252 / 0%) 78.46%), linear-gradient(180deg, #363846 26.79%, rgb(54 56 70 / 0%) 93.45%);
  --card-background-blur: 75px;
  --global-max-width: 41rem;
  --window-width: 100vw;
  --nav-height: 7.2rem;
  --nav-height-padded: calc(var(--nav-height) + var(--spacer-12));
  --announcement-banner-height: 3.6rem;
  --button-height: 3.775rem;
  --fab-height: 4.6rem;
  --border-radius-2: 0.2rem;
  --border-radius-5: 0.5rem;
  --border-radius-6: 0.6rem;
  --border-radius-8: 0.8rem;
  --border-radius-10: 1rem;
  --border-radius-12: 1.2rem;
  --border-radius-16: 1.6rem;
  --border-radius-18: 1.8rem;
  --border-radius-20: 2rem;
  --border-radius-40: 4rem;
  --border-radius-large: min(4vw, 4rem);
  --icons-border-radius: 22%;
  --card-border-radius: 2rem;
  --testimonial-card-radius: 1.2rem;

  --default-card-hover-scale-amount: 0.02;
  --bento-card-hover-scale-amount: 0.05;

  --section-border-radius: 2rem;
  --grid-max-content-width: var(--global-max-width);
  --grid-gutter-size: 12px;
  --grid-margin-size: 24px;
  --grid-number-of-columns: 4;
}

/* TODO: Refactor this box shadow, it's too much */

/* TODO: get designers to refine this shadow - i made it up */

/* TODO: ask designer to refine this gradient cause the numbers are crazy */

/* TODO: talk to designers about unifying the box shadow (same as above) */

/* Bentos V4 bg */

/* Gradients */

/* Blurs */

/* Max Widths  */

/* This value will be overriden by Javascript */

/* Nav Height variable used to prevent elements from being hidden underneath (and other calculations) */

/* button height  */

/* represents height of the fab button, used to calculate spacing when fab is sticky */

/* Common border radii */

/* Grid settings */

@media (min-width: 430px) {

:root {
    --global-max-width: 100vw;
    --grid-margin-size: 3.2rem;
}
  }

@media (min-width: 600px) {

:root {
    --grid-margin-size: 4rem;

    --global-max-width: 52rem;
}
  }

@media (min-width: 768px) {

:root {
    --grid-margin-size: 7.2rem;
    --nav-height: 8rem;
    --grid-number-of-columns: 12;
    --grid-gutter-size: 16px;
    --global-max-width: 100vw;

    /* button height  */
    --button-height: 3.975rem;
}
  }

@media (min-width: 960px) {

:root {
    --grid-margin-size: 9.8rem;
}
  }

@media (min-width: 1240px) {

:root {
    --global-max-width: 108rem;
    --grid-gutter-size: 24px;
}
  }

@media (min-width: 1921px) {

:root {
    --global-max-width: 112rem;
}
  }

:root {
  --grid-computed-margin-width: max(var(--grid-margin-size), var(--grid-gutter-size), (var(--window-width) - var(--grid-max-content-width)) / 2);

  --grid-computed-column-width: calc(min(var(--window-width) - 2 * var(--grid-computed-margin-width), var(--grid-max-content-width))/var(--grid-number-of-columns) - var(--grid-gutter-size)*(var(--grid-number-of-columns) - 1)/var(--grid-number-of-columns));
  --grid-computed-full-width: calc(var(--grid-gutter-size)*(var(--grid-number-of-columns) - 1) + var(--grid-computed-column-width)*var(--grid-number-of-columns) + var(--grid-computed-margin-width)*2);
  --grid-computed-inner-width: calc(var(--grid-gutter-size)*(var(--grid-number-of-columns) - 1) + var(--grid-computed-column-width)*var(--grid-number-of-columns));
  --duration-60ms: 0.06s;
  --duration-150ms: 0.15s;
  --duration-200ms: 0.2s;
  --duration-300ms: 0.3s;
  --duration-400ms: 0.4s;
  --duration-500ms: 0.5s;
  --duration-600ms: 0.6s;

  --ease-in: cubic-bezier(0.49, 0.025, 0.65, 0.65);
  --ease-out: cubic-bezier(0.28, 0.44, 0.49, 1);
  --ease-in-out: cubic-bezier(0.49, 0.025, 0.49, 1);
  --ease-micro-bounce: cubic-bezier(0, 0.44, 0.6, 1);
  --ease-default: cubic-bezier(0.25, 0.1, 0.25, 1);
  --ease-basic-butter: cubic-bezier(0.42, 0, 0.28, 0.99);
  --ease-embellishment: cubic-bezier(0.42, 0, 0, 1);
  --ease-mini-bounce: cubic-bezier(0.17, 0.89, 0.47, 1.13);
  --multi-devices-aspect-ratio: 1.662;
  --iphone-aspect-ratio: 0.492;
  --ipad-aspect-ratio: 1.385;
  --studio-display-aspect-ratio: 1.725;
  --macbook-pro-aspect-ratio: 154/100;
  --xl-scale-value: 1.145;
}

html {
  background-color: var(--color-grey-00);
  color: var(--main-font-color);
  font-size: 62.5%;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;

  /* adds scroll locking and padding to top of page when clicking an anchor link */
  scroll-padding-top: var(--scroll-padding-top);
}

@media (min-width: 1921px) {

html {
    /* We'll reach 72.5% at 2560 */
    font-size: min(72.5%, calc(62.5% + 0.25vw - 4.8px))
}
  }

/* stylelint-disable-next-line selector-id-pattern */

#__next {
  width: 100%;
  min-height: 100vh;
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  min-height: -webkit-fill-available;
  min-height: -moz-available;
  min-height: stretch;
  font-family: var(--font-main);
  font-size: var(--font-size-base);
}

/* stylelint-disable-next-line selector-class-pattern */

/* stylelint-disable-next-line selector-class-pattern */

.olderSafari .scrollable-container {
    contain: unset;
  }

/* Elements used by the WindowSize singleton to watch the window height and width respectively */

/* They're defined here to make sure they're defined in the global space as we target them via IDs */

#vertical-sizer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
}

#horizontal-sizer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}

/* Ensures the nav is on top of the drift bot */

#drift-frame-controller {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: var(--z-index-10) !important;
}

/* stylelint-disable selector-class-pattern */

.intercom-lightweight-app,
.intercom-app,
#intercom-css-container,
#intercom-container,
#intercom-frame {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

#modal {
  position: fixed;
  z-index: var(--z-index-modal);
  top: 0;
  left: 0;
}

select {
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

button {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--color-grey-100);
}

i,
em {
  font-style: normal;
}

a {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-decoration: inherit;

  /* color: var(--color-grey-100); */
}

/* stylelint-disable property-no-vendor-prefix */

input[type='number'],
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }

input[type='number']:hover,
    input[type='number']:focus,
    input[type='number']::-webkit-inner-spin-button:hover,
    input[type='number']::-webkit-inner-spin-button:focus,
    input[type='number']::-webkit-outer-spin-button:hover,
    input[type='number']::-webkit-outer-spin-button:focus {
      -moz-appearance: number-input;
    }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition-delay: 99999s;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* stylelint-disable-line */
}

*:focus {
  outline: none;
}

/* Hide focus styles if they're not needed, for example,
  when an element receives focus via the mouse. */

a:focus:not(:focus-visible), button:focus:not(:focus-visible) {
    outline: 0;
  }

/* Show focus styles on keyboard focus. */

a:focus-visible, button:focus-visible {
    outline: 1px dotted var(--color-grey-100);
  }

.noScroll {
  position: fixed;
  width: 100%;
}

/* fixes issue with ios open mobile nav and not being able to render the backdrop blur */

/* stylelint-disable-next-line selector-id-pattern */

.noScroll #__next {
    min-height: calc(var(--vh, 1vh)*100);
  }

.noSmoothScroll {
  scroll-behavior: auto;
}

.visuallyHidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
  opacity: 0.0001;
}

