/* stylelint-disable value-list-comma-newline-after */
/* stylelint-disable max-line-length */
/* stylelint-disable custom-property-empty-line-before */

:root {
  /* Font Settings */
  --font-size-base: 1.6rem;
  --main-font-color: var(--color-grey-100);

  /* Z-Indexes */
  --z-index-background-color: -10;
  --z-index-neg: -1;
  --z-index-0: 0;
  --z-index-1: 1;
  --z-index-2: 2;
  --z-index-3: 3;
  --z-index-4: 4;
  --z-index-5: 5;
  --z-index-6: 6;
  --z-index-7: 7;
  --z-index-8: 8;
  --z-index-9: 9;
  --z-index-10: 10;
  --z-index-nav: 100;
  --z-index-max: 105;
  --z-index-modal: 110;

  /* Shadows */

  --bento-glass-integrations-1: linear-gradient(221deg, #42545d, #203643 29%, #1c2935 45%, #1b242f 64%, #191d27 83%, #171820);
  --bento-glass-integrations-2: linear-gradient(226deg, #2e3d48 6%, #203643 18%, #1b242f 51%, #191d27 82%, #171820);
  --bento-glass-integrations-3: linear-gradient(208deg, #1e2e3a, #1c2833 19%, #1b242f 40%, #191d26 69%, #171820 88%, #171820);
  --bento-glass-integrations-4: linear-gradient(194deg, #1e2e3a, #1c2833 25%, #1b242f 38%, #191d26 69%, #171820 88%, #171820);
  --bento-hover-gradient: linear-gradient(47.17deg, #181821 7.98%, #1c2631 150.39%);

  --bento-glass-sub-integrations-1: linear-gradient(165.45deg, #272834 8.67%, #16171f 62.25%), linear-gradient(0deg, rgb(54 56 70 / 20%), rgb(54 56 70 / 20%));
  --bento-glass-sub-integrations-2: linear-gradient(165.45deg, #272834 8.67%, #16171f 62.25%), linear-gradient(0deg, rgb(54 56 70 / 20%), rgb(54 56 70 / 20%));
  --bento-glass-sub-integrations-3: linear-gradient(165.45deg, #272834 8.67%, #16171f 62.25%), linear-gradient(0deg, rgb(54 56 70 / 20%), rgb(54 56 70 / 20%));
  --bento-glass-sub-integrations-4: linear-gradient(165.45deg, #272834 8.67%, #16171f 62.25%), linear-gradient(0deg, rgb(54 56 70 / 20%), rgb(54 56 70 / 20%));

  /* borders */
  --bento-border: 1px solid rgb(54 56 70 / 20%);
  --glass-wrapper-border: 0.4rem;

  @media (--md) {
    --glass-wrapper-border: 0.6rem;
  }

  /* TODO: Refactor this box shadow, it's too much */
  --card-box-shadow: 84.6801px 149.711px 80px rgb(0 0 0 / 21%), 54.8852px 97.0347px 46.8519px rgb(0 0 0 / 15.9%), 32.6175px 57.6663px 25.4815px rgb(0 0 0 / 12.8%), 16.936px 29.9421px 13px rgb(0 0 0 / 10.5%), 6.89986px 12.1986px 6.51852px rgb(0 0 0 / 8.24%),
    1.56815px 2.77242px 3.14815px rgb(0 0 0 / 5.06%);

  /* TODO: get designers to refine this shadow - i made it up */
  --card-small-box-shadow: 32.6175px 57.6663px 25.4815px rgb(0 0 0 / 21.8%), 16.936px 29.9421px 13px rgb(0 0 0 / 15.5%), 6.89986px 12.1986px 6.51852px rgb(0 0 0 / 12.24%), 1.56815px 2.77242px 3.14815px rgb(0 0 0 / 5.06%);

  --button-box-shadow: 5px 7px 18px rgb(0 0 0 / 10%), 0 8px 21px rgb(1 0 19 / 15%);

  /* TODO: ask designer to refine this gradient cause the numbers are crazy */
  --button-glass-bg: radial-gradient(1386.01% 199.24% at 2.29% 8.82%, rgb(163 164 191 / 20%) 0%, rgb(193 194 217 / 11%) 44.34%);

  --button-border-color: rgba(#9d9dff, 0.05);

  --bento-box-shadow: 25px 38px 38px 0 rgb(0 0 0 / 45%);

  /* TODO: talk to designers about unifying the box shadow (same as above) */
  --faq-box-shadow: 57.4265px 55.6974px 109px rgb(0 0 0 / 38%), 29.0722px 28.1968px 47.5172px rgb(0 0 0 / 25.7%), 11.4853px 11.1395px 17.7125px rgb(0 0 0 / 19%);

  /* Bentos V4 bg */
  --card-background: linear-gradient(var(--bento-gradient-angle, 140deg), #1b1b26 var(--bento-gradient-start, -41.97%), #09090d var(--bento-gradient-end, 77.06%));

  /* Gradients */
  --interactive-quotes-gradient: linear-gradient(70.41deg, #5d3730 -52%, #8b5f4d 26.88%, #343357 75%);

  --fullwidthimage-text-gradient: linear-gradient(180deg, rgb(0 0 0 / 0%) 0.46%, rgb(0 0 0 / 60%) 18%, #000000 48%);

  --nav-gradient-when-blurred: transparent;

  --nav-gradient-when-unblurred: linear-gradient(to bottom, rgb(0 0 0 / 90%) 50%, rgb(0 0 0 / 50%) 100%);

  --image-sequence-gradient: linear-gradient(30.53deg, rgb(0 0 0 / 90%) 41.93%, rgb(0 0 0 / 23.4%) 63.18%), linear-gradient(360deg, rgb(0 0 0 / 92%) 15.86%, rgb(0 0 0 / 0%) 56.66%);

  --stat-gradient-enterprise: linear-gradient(155.1deg, #fcfcfc -2.85%, rgb(252 252 252 / 0%) 78.46%), linear-gradient(180deg, #363846 26.79%, rgb(54 56 70 / 0%) 93.45%);

  /* Blurs */
  --card-background-blur: 75px;

  /* Max Widths  */
  --global-max-width: 41rem;

  /* This value will be overriden by Javascript */
  --window-width: 100vw;

  /* Nav Height variable used to prevent elements from being hidden underneath (and other calculations) */
  --nav-height: 7.2rem;
  --nav-height-padded: calc(var(--nav-height) + var(--spacer-12));
  --announcement-banner-height: 3.6rem;

  /* button height  */
  --button-height: 3.775rem;

  /* represents height of the fab button, used to calculate spacing when fab is sticky */
  --fab-height: 4.6rem;

  /* Common border radii */
  --border-radius-2: 0.2rem;
  --border-radius-5: 0.5rem;
  --border-radius-6: 0.6rem;
  --border-radius-8: 0.8rem;
  --border-radius-10: 1rem;
  --border-radius-12: 1.2rem;
  --border-radius-16: 1.6rem;
  --border-radius-18: 1.8rem;
  --border-radius-20: 2rem;
  --border-radius-40: 4rem;
  --border-radius-large: min(4vw, 4rem);
  --icons-border-radius: 22%;
  --card-border-radius: 2rem;
  --testimonial-card-radius: 1.2rem;

  --default-card-hover-scale-amount: 0.02;
  --bento-card-hover-scale-amount: 0.05;

  --section-border-radius: 2rem;

  /* Grid settings */
  --grid-max-content-width: var(--global-max-width);
  --grid-gutter-size: 12px;
  --grid-margin-size: 24px;
  --grid-number-of-columns: 4;

  @media (min-width: 430px) {
    --global-max-width: 100vw;
    --grid-margin-size: 3.2rem;
  }

  @media (min-width: 600px) {
    --grid-margin-size: 4rem;

    --global-max-width: 52rem;
  }

  @media (--md) {
    --grid-margin-size: 7.2rem;
    --nav-height: 8rem;
    --grid-number-of-columns: 12;
    --grid-gutter-size: 16px;
    --global-max-width: 100vw;

    /* button height  */
    --button-height: 3.975rem;
  }

  @media (min-width: 960px) {
    --grid-margin-size: 9.8rem;
  }

  @media (--lg) {
    --global-max-width: 108rem;
    --grid-gutter-size: 24px;
  }

  @media (--xl) {
    --global-max-width: 112rem;
  }

  /* Computed grid variables */

  /* Grid edge margins */
  --grid-computed-margin-width: max(var(--grid-margin-size), var(--grid-gutter-size), (var(--window-width) - var(--grid-max-content-width)) / 2);

  /* Grid column width */

  --grid-computed-column-width: calc(min(var(--window-width) - 2 * var(--grid-computed-margin-width), var(--grid-max-content-width)) / var(--grid-number-of-columns) - var(--grid-gutter-size) * (var(--grid-number-of-columns) - 1) / var(--grid-number-of-columns));

  /* Max grid width, with margins */
  --grid-computed-full-width: calc(var(--grid-gutter-size) * (var(--grid-number-of-columns) - 1) + var(--grid-computed-column-width) * var(--grid-number-of-columns) + var(--grid-computed-margin-width) * 2);

  /* Max grid width, not including margins */
  --grid-computed-inner-width: calc(var(--grid-gutter-size) * (var(--grid-number-of-columns) - 1) + var(--grid-computed-column-width) * var(--grid-number-of-columns));

  /* Animations */
  --duration-60ms: 0.06s;
  --duration-150ms: 0.15s;
  --duration-200ms: 0.2s;
  --duration-300ms: 0.3s;
  --duration-400ms: 0.4s;
  --duration-500ms: 0.5s;
  --duration-600ms: 0.6s;

  --ease-in: cubic-bezier(0.49, 0.025, 0.65, 0.65);
  --ease-out: cubic-bezier(0.28, 0.44, 0.49, 1);
  --ease-in-out: cubic-bezier(0.49, 0.025, 0.49, 1);
  --ease-micro-bounce: cubic-bezier(0, 0.44, 0.6, 1); /* ease-out */
  --ease-default: cubic-bezier(0.25, 0.1, 0.25, 1); /* ease-out-soft */
  --ease-basic-butter: cubic-bezier(0.42, 0, 0.28, 0.99); /* ease-out-softer */
  --ease-embellishment: cubic-bezier(0.42, 0, 0, 1); /* ease-in-out */
  --ease-mini-bounce: cubic-bezier(0.17, 0.89, 0.47, 1.13); /* ease-mini-bounce */

  /* Aspect Ratios */
  --multi-devices-aspect-ratio: 1.662;
  --iphone-aspect-ratio: 0.492;
  --ipad-aspect-ratio: 1.385;
  --studio-display-aspect-ratio: 1.725;
  --macbook-pro-aspect-ratio: 154/100;

  /* per design system, everything (grid, type styles, margins, etc) fluidly scales up from 1.0 to 1.145 */
  --xl-scale-value: 1.145;
}
